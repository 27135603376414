import React, { useEffect, useState, useRef } from "react";
import settings from "../website_settings.json";
import HeroSection from "./HeroSection";
import ReactPlayer from "react-player";
import InstagramGallery from "./InstagramGallery";
import LoadingSpinner from "./LoadingSpinner";

const LandingPage: React.FC = () => {
  const [videoReady, setVideoReady] = useState(false);
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [calcWidth, setCalcWidth] = useState<number>(0);
  const [calcHeight, setCalcHeight] = useState<number>(0);
  const [videoZoom, setVideoZoom] = useState<number>(1);
  const [blurIntensity, setBlurIntensity] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);

  const isLoaded = videoReady && backgroundImageLoaded && assetsLoaded;

  useEffect(() => {
    const handleResize = () => {
      const newWindowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      const windowAspectRatio =
        newWindowDimensions.width / newWindowDimensions.height;
      const videoAspectRatio = 16 / 9;

      let newVideoZoom = 1;

      if (windowAspectRatio > videoAspectRatio) {
        newVideoZoom =
          newWindowDimensions.width /
          (newWindowDimensions.height * videoAspectRatio);
      } else {
        newVideoZoom =
          (newWindowDimensions.height * videoAspectRatio) /
          newWindowDimensions.width;
      }

      setVideoZoom(newVideoZoom);
      setCalcWidth(newWindowDimensions.width);
      setCalcHeight(newWindowDimensions.height);
    };

    const backgroundImage = new Image();
    backgroundImage.src = settings.backgroundImagePath;
    backgroundImage.onload = () => {
      console.log("Background image loaded");
      setBackgroundImageLoaded(true);
    };
    backgroundImage.onerror = () => {
      console.error("Failed to load background image");
      setBackgroundImageLoaded(true);
    };

    const handleAssetsLoad = () => {
      const allAssets = Array.from(document.querySelectorAll("img"));
      const assetPromises = allAssets.map((asset) =>
        new Promise((resolve) => {
          if (asset instanceof HTMLImageElement) {
            if (asset.complete) {
              resolve(true);
            } else {
              asset.addEventListener("load", resolve);
              asset.addEventListener("error", resolve);
            }
          } else {
            resolve(true);
          }
        })
      );

      Promise.all(assetPromises).then(() => {
        console.log("All assets loaded");
        setAssetsLoaded(true);
      });
    };

    handleResize();
    handleAssetsLoad();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const navbar = document.querySelector(".navbar") as HTMLElement;
      const heroSection = document.querySelector(
        ".hero-section"
      ) as HTMLElement;
      const aboutSection = document.querySelector(
        "#about-section"
      ) as HTMLElement;

      if (navbar) {
        navbar.style.transform =
          scrollY > heroSection.clientHeight ? "translateY(0)" : "translateY(-100%)";
      }

      const aboutSectionRect = aboutSection?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const distanceFromCenter = Math.abs(
        (aboutSectionRect?.top ?? 0) +
          (aboutSectionRect?.height ?? 0) / 2 -
          viewportHeight / 2
      );
      const blurAmount = Math.min(5, (distanceFromCenter / viewportHeight) * 20);
      setBlurIntensity(blurAmount);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="relative">
      {/* Loading Spinner Overlay */}
      <div
        className="loading-spinner-container fixed inset-0 flex items-center justify-center bg-white"
        style={{
          zIndex: isLoaded ? -1 : 1000,
          opacity: isLoaded ? 0 : 1,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <LoadingSpinner />
      </div>

      {/* Main Content */}
      <div
        className="main-content-container"
        style={{
          zIndex: isLoaded ? 1 : -1,
          opacity: isLoaded ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <HeroSection />
        <div
          id="about-section"
          className="video-container mb-20"
          style={{ height: `${calcHeight}px` }}
        >
          {calcWidth && calcHeight && (
            <ReactPlayer
              ref={playerRef}
              url={settings.backgroundVideoUrl}
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              height={`${calcHeight}px`}
              width={calcWidth}
              playsinline
              onProgress={(progress) => {
                if (progress.playedSeconds > 0){
                  setVideoReady(true)
                }
              }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%) scale(${videoZoom})`,
                objectFit: "cover",
                filter: `blur(${blurIntensity}px)`,
              }}
              config={{
                youtube: {
                  playerVars: {
                    controls: 0,
                    modestbranding: 1,
                    rel: 0,
                    iv_load_policy: 3,
                    enablejsapi: 1,
                  },
                },
              }}
            />
          )}
          <div className="overlay absolute inset-0 flex items-center justify-center">
            <div className="text-box bg-white bg-opacity-75 rounded shadow-lg">
              <p className="about-text">{settings.aboutText}</p>
            </div>
          </div>
        </div>

        <div className="container instagram-gallery mb-20">
          <InstagramGallery />
        </div>

        <div
          id="contact-section"
          className="container address-contact-location content-center grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          <div className="openingtimes-contact">
            <div className="opening-times p-4">
              <h2 className="text-xl font-bold mb-2">Opening Times</h2>
              <div className="opening-times-grid">
                {Object.entries(settings.openingTimes).map(([day, time]) => (
                  <div key={day} className="opening-time-row">
                    <span className="day">{day}</span>
                    <span className="time">{time}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="contact p-4">
              <h2 className="text-xl font-bold mb-2">Contact Info</h2>
              <p>{settings.address.street}</p>
              <p>
                {settings.address.postalCode}, {settings.address.City}{" "}
              </p>
              {settings.address.Country}
              <a className="email-ref" href={`mailto:${settings.email}`}>
                {settings.email}
              </a>
            </div>
          </div>
          <div className="map-location p-4">
            <iframe
              title="Google maps iframe"
              src={`${settings.googleMapsLink}`}
              width="100%"
              height="200"
              allowFullScreen
              loading="lazy"
              className="rounded"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
