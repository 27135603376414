import React, { useState, useEffect } from "react";
import settings from "../website_settings.json";

// FIXME - for mobile it will still load and it won't allow scrolling - making the page useless

const styles = {
  container: {
    width: "100%",
    maxWidth: "auto", // Make the container wider
    backgroundColor: "#ffffff", // White background for the container
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Light shadow for some depth
    borderRadius: "8px", // Rounded corners
  },
  pdfViewer: {
    paddingTop: "clamp(0.75vw, 3.5vw - 1rem, 5vw)",
    width: "100%",
    height: "100vh", // Increase the height of the PDF viewer
    border: "none", // Remove border
    backgroundColor: "#ffffff", // White background for the PDF viewer
  },
};

// Check if the URL is a PDF file
const isPdfFile = (url: string): boolean => {
  return url.endsWith(".pdf");
};

// Main Menu component
const Menu: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [pdfFailed, setPdfFailed] = useState<boolean>(false); 

  useEffect(() => {
    if (settings.menuPdf && isPdfFile(settings.menuPdf)) {
      // Append parameters to disable toolbar and side view
      setPdfUrl(`${settings.menuPdf}#toolbar=0&navpanes=0&zoom=150`);
    } else {
      setError("Invalid PDF file path.");
    }
  }, []);

  const handlePdfError = () => {
    setPdfFailed(true);
  };

  if (error) {
    return (
      <div className="page-container">
        <div style={styles.container}>
          <p>Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div style={styles.container}>
        {pdfUrl && !pdfFailed ? (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100vh"
            style={styles.pdfViewer}
            onError={handlePdfError}
          >
            {/* Fallback if the PDF cannot be loaded in the browser */}
            <p style={{ padding: "1.2rem" }}>
              Your web browser doesn't support viewing PDFs. You can&nbsp;
              <a href={pdfUrl} download target="_blank" rel="noopener noreferrer">
                <span style={{ color: "#0000FF", textDecoration: "underline" }}>
                  click here
                </span>
              </a>
              &nbsp;to download the PDF file instead.
            </p>
          </object>
        ) : (
          // Show fallback message if PDF loading failed or isn't available
          <div style={styles.container}>
            <p>No menu available at the moment. Please check back later.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
