import React from 'react';
import { CircularProgress } from '@mui/material';
import settings from '../website_settings.json';

const LoadingSpinner: React.FC = () => {
  return (
    <div
      className="loading-spinner"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ffffff',
        position: 'relative',
      }}
    >
      {/* Circular Spinner */}
      <CircularProgress
        size={200} // Larger size to envelop the logo
        thickness={3} // Thinner progress bar
        style={{
          color: '--primary-color', // Adjust color as needed
          position: 'absolute', // Centered over the logo
        }}
      />
      {/* Spinning Logo */}
      <img
        src={settings.logoPath}
        alt="Loading logo"
        style={{
          width: '150px', // Adjust as needed for desired proportion
          height: '150px',
          animation: 'spin 2s linear infinite', // Spin the logo itself
        }}
      />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingSpinner;
